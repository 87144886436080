.drillName {
  text-align: center;
  background-color: white;
  margin: 0;
  padding: 1rem 0;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}

.drillLevels {
  display: flex;
  justify-content: space-between;
  border-radius: 0.25rem;
  background-color: white;
}

.drillLevel {
  flex: 1 1 auto;
  text-align: center;
  color: gray;
}

.activeLevel {
  color: black;
}

.drillLevel:not(:last-child) { 
  border-right: 1px solid gray;
}

.drillBody {
  margin: 0 1rem;
}

.drillShowButtons {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.drillShowButton {
  text-align: center;
  background-color: white;
  border-radius: 0.5rem;
  padding: 1rem 2rem;
  margin: 0 1rem;
}

.drillDescription {
  background-color: white;
  border-radius: 0.5rem;
  padding: 1rem;
  margin-bottom: 1rem;
}