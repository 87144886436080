.integerComparison {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.integerComparisonSign {
  font-size: 5rem;
}

.integerComparisonNumber {
  margin: 0;
  font-size: 4rem;
}