.runShow {
  padding-bottom: 3rem;
}

.runForm {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: white;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}

.tryToggle {
  border-radius: 0.5rem;
  padding: 1rem;
  margin: 0.5rem;
  background-color: #E05656;
  flex: 0 0 calc(33.33% - 1rem);
  box-sizing: border-box;
  text-align: center;
}

.success {
  background-color: #89F79A;
}

.resultWrapper {
  padding: 1rem;
  background-color: white;
  border-radius: 0.5rem;
  height: 35vw;
}

.resultStats {
  display: flex;
  gap: 1rem;
}

.resultStats > * {
  flex: 1 1 0;
}

.runAgainButtons {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  justify-content: center;
  gap: 1rem;
}

.submitRunButton, .runAgainButton {
  margin: 0 auto;
}