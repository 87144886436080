.last5runs {
  background-color: white;
  border-radius: 0.5rem;
  padding: 1rem;
}

.linearPercentageWrapper {
  display: flex;
  padding: 0.25rem;
}

.linearPercentageLabel {
  white-space: nowrap;
  margin: 0 1rem;
}

.statsRow {
  display: flex;
  margin: 1rem;
}

.numberBox {
  flex-grow: 1;
}

.numberBoxNumber {
  text-align: center;
  background-color: white;
  border-radius: 0.5rem;
  padding: 1rem;
  margin: 0 1rem;
}