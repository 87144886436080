.linear-chart {
  height: 2rem;
}

.line {
  stroke: #4CC790;
  fill: none;
  stroke-width: 2.8;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
}