@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');

body {
  margin: 0;
  font-family: 'Lexend';
  background-color: #EBEBEB;
}

a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}

.button {
  border-radius: 0.5rem;
  padding: 1rem 2rem;
  background-color: white;
  width: fit-content;
  font-weight: bold;
}

.boxLabel {
  margin: 0.25rem 0;
  font-weight: bold;
}

.f125 {
  font-size: 1.25rem;
}
