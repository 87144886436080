.userShow {
  padding: 0 3rem;
}

.userName {
  font-weight: bold;
  font-size: 1.5rem;
  margin-left: -2rem;
}

.progressWrapper {
  background-color: white;
  padding: 1rem;
  border-radius: 0.5rem;
  margin: 1rem 0;
}

.progressChartLabel {
  font-weight: bold;
  margin-bottom: 0;
}

.progressBarChart {
  display: flex;
  align-items: center;
}

.progressBar {
  width: 100%;
}

.progressScore {
  margin: 0;
}