.header {
  background-color: white;
  text-align: center;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerTitle {
  margin: 0;
  padding: 0.25rem;
}

.headerIcon {
  font-size: 2rem;
  padding: 1rem;
}