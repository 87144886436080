.pathContainer {
  display: flex;
  flex-wrap: wrap;
}

.pathPoint {
  flex: 40%;
  box-sizing: border-box;
  font-size: 1.5rem;
  width: fit-content;
  padding: 1rem;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem;
  background-color: #dbd9d9;
  border-radius: 0.5rem;
}

.pathPointIcon {
  font-size: 3rem;
  padding: 1rem;
  background-color: white;
  border-radius: 50%;
}


.pathPointName {
  margin: 0;
  background-color: white;
  border-radius: 0.5rem;
  margin-top: 0.5rem;
  padding: 0.25rem 0.5rem;
  text-align: center;
}
