.drillList {
  margin: 0 1rem;
}

.drillListItem {
  background-color: white;
  border-radius: 0.5rem;
  padding: 1rem;
  margin: 2rem 0;
}

.drillListItemName {
  font-size: 1.25rem;
  margin:0;
}
