.signForm {
  display: flex;
  flex-direction: column;
  padding: 0 5rem;
}

.signLabel {
  margin-bottom: 0;
  font-weight: bold;
}

.signInput {
  border: 0;
  margin-bottom: 2rem;
  font-size: 2rem;
}

.signButton {
  font-size: 2rem;
  border-radius: 0.25rem;
  background-color: white;
  border: 3px solid black;
  padding: 0.5rem 1rem;
  width: fit-content;
  margin: 0 auto;
}

.signAltLink {
  display: block;
  width: fit-content;
  margin: 3rem auto;
  text-decoration: underline;
}